var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('base-table-with-header',{attrs:{"data":{
      headerOptions: { withSearch: true, withExport: true },
      tableHeader: _vm.studentCoursesHeaders,
      tableItems: _vm.studentCoursesRows,
      extraOptions: {
        footerOptions: {
          itemsPerPageText: this.$t('table.rowNumber'),
        },
      },
      withActions: true,
    }}},[_c('h1',{staticClass:"font-weight-bold",style:({ color: this.primaryColor }),attrs:{"slot":"tableTitle"},slot:"tableTitle"},[_vm._v(" "+_vm._s(this.$t("students.courses.title"))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }