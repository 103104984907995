<template>
  <v-row class="d-flex align-center uk-margin-auto" style="justify-content: flex-end !important">
    <download-excel 
      :data="data.tableItems"
      name="StudentsCourses">
      <v-btn class="white--text ma-2" v-if="data.headerOptions.withExport" :color="this.YColor">
      <v-icon>{{this.downloadIcon}}</v-icon>
      {{ $t('table.export') }} 
    </v-btn>
    </download-excel>
  </v-row>
</template>

<script>
export default {
  name: "TableActionsNav",
  props : {
    options : Object,
    data: Object
  },
  data(){
    return {
      search : '',
    }
  }
}
</script>

<style scoped>

</style>
