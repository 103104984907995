<template>
  <v-row>
    <base-table-with-header
      :data="{
        headerOptions: { withSearch: true, withExport: true },
        tableHeader: studentCoursesHeaders,
        tableItems: studentCoursesRows,
        extraOptions: {
          footerOptions: {
            itemsPerPageText: this.$t('table.rowNumber'),
          },
        },
        withActions: true,
      }"
    >
      <h1
        slot="tableTitle"
        class="font-weight-bold"
        :style="{ color: this.primaryColor }"
      >
        {{this.$t("students.courses.title")}}
      </h1>
    </base-table-with-header>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseTableWithHeader from "../CoursesTableComponents/CoursesBactTable";
export default {
  name: "Instructors",
  components: { BaseTableWithHeader },
  data() {
    return {
      studentCoursesHeaders: [
        {
          text: this.$t("students.courses.ID"),
          value: "course_id",
          align: "center",
        },
        {
          text: this.$t("students.courses.courseTitle"),
          value: "course_title",
          align: "center",
        },
        {
          text: this.$t("students.courses.price"),
          value: "course_price",
          align: "center",
        },
        {
          text: this.$t("students.courses.courseType"),
          value: "course_type",
          align: "center",
        },
        {
          text: this.$t("students.courses.categoryTitle"),
          value: "Category_Title",
          align: "center",
        },
        {
          text: this.$t("students.courses.totalEnrolls"),
          value: "total_enrolls",
          align: "center",
        },
        {
          text: this.$t("students.courses.totalCompleted"),
          value: "total_completed",
          align: "center",
        },
      ],
      studentCoursesRows: [],
    };
  },
  created() {
    this.getStudentCourses(this.$route.params.id).then(() => {
      this.displayStudentCourses();
    });
  },
  methods: {
    ...mapActions("DashBoard/Students", ["getStudentCourses"]),
    displayStudentCourses() {
      this.studentCoursesRows = [];
      this.getStudCourses.forEach((courses) => {
        const displayStudentCourses = { ...courses };
        this.studentCoursesRows.push(displayStudentCourses);
      });
    },
  },
  computed: mapGetters("DashBoard/Students", ["getStudCourses"]),
};
</script>

<style scoped>
</style>
