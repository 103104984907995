<template>
  <v-row>
    <base-table-with-header
      :data="{
        headerOptions: { withSearch: true , withExport: true },
        tableHeader: studentInstructorsHeaders,
        tableItems: studentInstructorsRows,
        extraOptions: {
          footerOptions: {
            itemsPerPageText: this.$t('table.rowNumber'),
          },
        },
        withActions: true,
      }"
    >
      <h1
        slot="tableTitle"
        class="font-weight-bold"
        :style="{ color: this.primaryColor }"
      >
        {{this.$t("students.instructors.title")}}
      </h1>
    </base-table-with-header>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseTableWithHeader from "../InstructorTableComponents/InstructorsBactTable";
export default {
  name: "Instructors",
  components: { BaseTableWithHeader },
  data() {
    return {
      studentInstructorsHeaders: [
        {
          text: this.$t("students.instructors.ID"),
          value: "instructor_id",
          align: "center",
        },
        {
          text: this.$t("students.instructors.name"),
          value: "instructor_name",
          align: "center",
        },
        {
          text: this.$t("students.instructors.email"),
          value: "instructor_email",
          align: "center",
        },
        {
          text: this.$t("students.instructors.courses"),
          value: "courses",
          align: "center",
        },
      ],
      studentInstructorsRows: [],
    };
  },
  created() {
    this.getStudentInstructors(this.$route.params.id).then(() => {
      this.displayStudentInstructors();
    });
  },
  methods: {
    ...mapActions("DashBoard/Students", ["getStudentInstructors"]),
    displayStudentInstructors() {
      this.studentInstructorsRows = [];
      this.getStudInstructors.forEach((instructors) => {
        const displayStudentInstructors = { ...instructors };
        this.studentInstructorsRows.push(displayStudentInstructors);
      });
    },
  },
  computed: mapGetters("DashBoard/Students", ["getStudInstructors"]),
};
</script>

<style scoped>
</style>
