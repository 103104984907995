<template>
  <v-avatar
    class="avatar"
    color="grey"
    tile
  >
    <slot></slot>
  </v-avatar>
</template>

<script>
export default {
  name: "BaseAvatar"
}
</script>

<style scoped>
    .avatar {
        border: 2px solid grey !important;
        border-radius: 10px !important;
        width: 100% !important;
        height: 100% !important
    }
</style>
