<template>
  <v-card>
    <v-container>
      <v-row justify="center">
        <v-col lg="12" sm="12" cols="12">
          <v-tabs
            v-model="tab"
            :background-color="this.grayColor"
            :color="this.primaryColor"
            centered
            mobile-breakpoint="md"
            grow
            height="90"
            loading
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#basic-info" class="subtitle-1">
              <v-icon class="icon">{{ personalIcon }}</v-icon>
              <div>{{ this.$t("students.info.studentInfo") }}</div>
            </v-tab>

            <v-tab href="#courses" class="subtitle-1">
              <v-icon class="icon">{{ coursesIcon }}</v-icon>
              {{ this.$t("students.courses.title") }}
            </v-tab>

            <v-tab href="#instructors" class="subtitle-1">
              <v-icon class="icon">{{ schoolIcon }}</v-icon
              >{{ this.$t("students.instructors.title") }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item v-for="stu in student" :key="stu.id" value="basic-info">
              <StudentInfo
                :avatar="stu.avatar"
                :firstName="stu.firstName"
                :lastName="stu.lastName"
                :email="$route.query.email"
                :createdAt="stu.createdAt"
                :phone="stu.phone"
                :identityNumber="stu.identityNumber"
                :country="stu.country"
                :birthDate="stu.birthDate"
              />
            </v-tab-item>
            <v-tab-item value="courses">
              <StudentCourses />
            </v-tab-item>
            <v-tab-item value="instructors">
              <StudentInstructors />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import StudentInfo from "../../../Components/StudentInfo.vue";
import StudentCourses from "../components/CoursesTableComponents/StudentCourses";
import StudentInstructors from "../components/InstructorTableComponents/StudentInstructors";
import { mapActions, mapState } from "vuex";
export default {
  name: "viewStudents",
  components: { StudentInfo, StudentCourses, StudentInstructors },
  data () {
    return {
      tab: null,
    }
  },
  computed: {
    ...mapState("DashBoard/Students", ["student"]),
  },
  methods: {
    ...mapActions("DashBoard/Students", ["getStudentInfo"]),
  },
  created() {
    console.log(this.$route.params.id);
    this.getStudentInfo(this.$route.params.id);
  },
};
</script>
<style lang="scss" scoped>
.icon {
  margin-left: 10px;
}
.card {
  height: 1000px;
  width: 2000px;
}
</style>