<template>
  <v-form>
    <v-container>
      <v-row class="d-flex align-center">
        <h1>
          <base-arrow-back-icon @click="$router.go(-1)">{{rightArrowIcon}}</base-arrow-back-icon>
          {{this.$t("students.info.studentInfo")}}
        </h1>
      </v-row>
    </v-container>
    <v-container>
      <v-row class="first-row">
        <v-col cols="12" md="6">
          {{this.$t("students.info.avatar")}}
          <base-avatar>
            <v-img :src="getBased(avatar)"></v-img>
          </base-avatar>
        </v-col>
        <v-col cols="12" md="6"></v-col>
      </v-row>

      <br />
      <br />
      <v-row class="second-row">
        <v-col cols="12" md="4">
          {{this.$t("students.info.First")}}
          <v-text-field v-model="firstName" solo readonly></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          {{this.$t("students.info.Last")}}
          <v-text-field v-model="lastName" solo readonly></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          {{this.$t("students.info.CreatedAt")}}
          <v-text-field v-model="createdAt" solo readonly></v-text-field>
        </v-col>
      </v-row>

      <v-row class="third-row">
        <v-col cols="12" md="6">
          {{this.$t("students.info.Phone")}}
          <v-text-field v-model="phone" solo readonly></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          {{this.$t("students.info.IdentityNumber")}}
          <v-text-field v-model="identityNumber" solo readonly></v-text-field>
        </v-col>
      </v-row>

      <v-row class="fourth-row">
        <v-col cols="12" md="6">
          {{this.$t("students.info.Country")}}
          <v-text-field v-model="country" solo readonly></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          {{this.$t("students.info.BirthDate")}}
          <v-text-field v-model="birthDate" solo readonly></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import BaseArrowBackIcon from "../../../core/Base/Arrows/BaseArrowBackIcon";
import BaseAvatar from "../../../core/Base/Avatars/BaseAvatar";
import { mapActions, mapState } from "vuex";
export default {
  name: "StudentInfo",
  props: [
    "avatar",
    "firstName",
    "lastName",
    "createdAt",
    "phone",
    "identityNumber",
    "country",
    "birthDate"
  ],
  components: {
    BaseAvatar,
    BaseArrowBackIcon
  }
};
</script>

<style scoped>
</style>
